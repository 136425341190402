a {
    text-decoration: none;
}

.App {
    text-align: center;
}

.App-header {
    background-color: #eef2f6;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.menu-header-logo {
    display: flex;
    justify-content: center;
}

.menu-header-logo-left {
    display: flex;
    margin-left: 44px;
}

.fa-2x {
    font-size: 2em;
}

.avatar {
    width: 50%;
    border-radius: 50%;
    border-style: double;
    border: solid 2px #f5f6fa;
    background-position: center;
    background-size: cover;
}

.avatar:after {
    content: '';
    padding-bottom: 100%;
    display: block;
}

.fa {
    position: relative;
    display: table-cell;
    width: 60px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
}

#avatarBar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-style: double;
    border: solid 2px #f5f6fa;
    background-position: center;
    background-size: cover;
}

#main-menu:hover {
    width: 285px;
    overflow-x: hidden;
    overflow-y: auto;
}

#main-menu {
    background: #2c3e51;
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    width: 104px;
    overflow: hidden;
    -webkit-transition: width 0.05s linear;
    transition: width 0.1s linear;
    -webkit-transform: translateZ(0) scale(1, 1);
    z-index: 1000;
    box-shadow: 0 19px 36px 0 rgba(44, 62, 81, 0.26);
}

#main-menu > ul {
    margin: 7px 0;
}

#main-menu li {
    position: relative;
    display: block;
    width: 285px;
}

#main-menu li > a {
    position: relative;
    display: block;
    border-collapse: collapse;
    border-spacing: 0;
    color: #ffffff;
    font-family: arial;
    font-size: 14px;
    text-decoration: none;
    -webkit-transform: translateZ(0) scale(1, 1);
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
    margin-left: 5px;
    vertical-align: middle;
    padding: 5px 0;
    width: 270px;
}

.alinha {
    padding-left: 44px;
}

#main-menu .nav-icon {
    position: relative;
    display: table-cell;
    width: 60px;
    height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
}

#main-menu .nav-text {
    width: 51px;
    height: 17px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.no-touch .scrollable.hover {
    overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
    overflow-y: auto;
    overflow: visible;
}

nav {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

nav ul,
nav li {
    outline: 0;
    margin: 0;
    padding: 0;
}

.area {
    float: left;
    background: #ffffff;
    width: 100%;
    height: 100%;
}

.material-icons.md-24 {
    font-size: 24px;
    opacity: 0.54;
}

.dropdown > * {
    width: 150px;
    height: 17px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.dropdown > div {
    margin-left: 44px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
}

.dropdown > div.divConfig {
    padding-top: 14px;
}

ul > li.barList:hover,
ul > li.barList.activate,
ul > li.barList:focus {
    background-color: rgba(192, 192, 192, 0.3);
    width: 100%;
    text-decoration: none;
}

ul > li.barList a {
    width: 100%;
    height: 100%;
}

.divConfig > i {
    margin-left: 35px;
}

.divWorking > i {
    margin-left: 60px;
}

.divWorkPlan > i {
    margin-left: 13px;
}

.planning {
    width: 55px;
    height: 11px;
    opacity: 0.54;
    font-family: Roboto;
    font-size: 9px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 3.11px;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-left: 44px;
}

.App-header {
    background-color: red;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Rectangle-128 {
    width: 572px;
    height: 459px;
    box-shadow: 0 20px 40px 0 rgba(44, 62, 81, 0.2);
    background-color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
}

/* =================================== ALL APP STYLES ====================================================== */

.container {
    margin-left: calc(50px + 104px); /* 104 = sidebar size */
    margin-right: 50px;
    /* margin-bottom: 50px; */
}
.containerNewDesign {
    margin-top: 20px;
    margin-left: calc(50px + 104px); /* 104 = sidebar size */
    margin-right: 50px;
    /* margin-bottom: 50px; */
}

.headerNewDesign {
    padding-left: calc(50px + 104px); /* 104 = sidebar size */
    padding-right: 50px;
    background-color: white;
}

.container-headerNewDesign {
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.container-header {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.welcome {
    pointer-events: none;
    background-color: transparent;
    cursor: default;
}

.menuSelectStyle {
    float: left;
    display: none;
    background-color: #7ea3b7;
    color: #7ea3b7;
    height: 38px;
    width: 5px;
}

.lineBreak {
    background: #f1f1f3;
    height: 1px;
    margin: 5px 0 10px 0;
    width: 100%;
}

.colorKey {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    transform: translate(0, 1.5px) scale(0.75);
    position: absolute;
    transform-origin: right;
    display: block;
    padding-top: 10px;
    padding-left: 10px;
}

.divCircle {
    display: flex;
    margin-top: 7px;
}

.spanColorKey {
    margin-left: -12px;
    margin-top: 23px;
}
.headerContent {
    background-color: #f5f6fa;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 3.5;
    letter-spacing: normal;
    text-align: left;
    color: #6d6f71;
    padding: 0px 18px;
}

button {
    border: none;
}
