body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eef2f6;
  
  /* background-color:  blue; */
}

@media print {
    img {
        max-width: 100% !important;
    }
    @page {
        size: A4 landscape;
        margin: 0mm;
        padding: 0mm;
    }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.version {
  float: right;
  margin-right: 45%;
  color:#2c3e51;
  height: 50px; 
  /* font-weight: bold; */
}

.footer {
  
  position: inherit;
   bottom: 0;
   width: 100%;

  /* */
  margin-top: 30px; 
  height: 60px;
  color: #828b91;
  text-align: center;
  border-top:  #cfd5db solid 1px;
}
.Caminho-2050 > p {
  margin-left: -10px;
  float: left;
  font-size: 22px;
  width: 59.2px;
  margin-top: 17px;
  height: 22px;
  color: #2c3e51;
}
.version {
  float: right;
  margin-right: 40px;
  margin-top: 23px;
  color: #828b91;
}
.descricao {
  margin-left: 18px;
  float: left;
  margin-top: 23px;
  color: #828b91;
}
.logoufc {
  margin-top: 13px;
  margin-left: 155px;
  float: left;
  object-fit: contain;
}
.MuiPickersMonthSelection-container{
  text-transform: capitalize;
}