.form {
  padding: 20px;
  background-color: #f7f8f9;
  border-radius: 0.2rem;
}

.container {
  padding: 0;
}

.spinner {
  height: calc(100vh - 70px);
  display: "flex";
  align-items: "center";
}
