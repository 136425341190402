.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-bottom: 1px solid #dee2e6;
  min-width: 100px;
}
.table th {
  text-align: left;
  width: 57px;
  height: 19px;
  opacity: 0.54;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  min-width: 100px;
}
.secao {
  width: 83px;
  height: 19px;
  opacity: 0.54;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  box-align: left;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.cabecalho {
  background-color: #f5f6fa;
  opacity: 0.54;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.5;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-left: 18px;
}
