.Selectable {
  position: absolute;
  background: white;
  z-index: 1;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #2c3e51;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.DayPicker-Day--today {
  color: black;
  font-weight: bolder;
}

.Selectable .DayPicker-Day--start {      
  background-color: #2c3e51 !important;
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
  background-color: #2c3e51 !important;
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}